<template>
  <div :class="bodyClass">
    <div class="container">
      <div class="row">
        <span class="header text-left text-uppercase">
          <b>Météo</b><br />Lac Annecy
        </span>
      </div>
    </div>
    <div class="globalFond">
      <!-- Section Météo -->
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="card text-center">
              <div class="card-body" style="font-size: 1em; padding: 0em">
                <p class="text-justify">
                  <span class="header-small text-center">
                    Votre météo complète du lac d'Annecy en direct.
                  </span>
                  <br />
                  Vous trouverez la température de l'eau, de l'air, le vent
                  ainsi que notre bulletin en direct pour une vue d'ensemble des
                  conditions météorologiques du lac.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Section Températures -->
      <div class="container mt-5">
        <h4 class="card-title titresections">Températures en direct</h4>
        <div class="row">
          <div class="col-lg-6 col-md-12 col-6">
            <div class="card text-center">
              <div class="card-body">
                <span class="material-symbols-outlined display-4"
                  >thermostat</span
                >
                <h4 class="card-title">Température air</h4>
                <b class="card-text display-5" :class="classCouleurs('air')"
                  ><span v-if="lastTemperatureAir">{{
                    lastTemperatureAir
                  }}</span
                  ><span v-else>-</span><i class="wi wi-celsius"></i
                ></b>
                <br />
                <small style="font-size: 0.7em">
                  Mise à jour: {{ lastTemperatureTime }}
                </small>
                <hr class="d-none d-md-block" />
                <span class="col-6 d-none d-md-inline">
                  <div id="temperatureAirChartContainer">
                    <canvas id="temperatureAirChart"></canvas>
                  </div>
                </span>
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-md-12 col-6">
            <div class="card text-center">
              <div class="card-body">
                <span class="material-icons display-4">dew_point</span>
                <h4 class="card-title">Température eau</h4>
                <b class="card-text display-5" :class="classCouleurs('eau')"
                  ><span v-if="lastTemperatureEau">
                    {{ lastTemperatureEau }} </span
                  ><span v-else>-</span><i class="wi wi-celsius"></i
                ></b>
                <br />
                <small style="font-size: 0.7em">
                  Mise à jour: {{ lastTemperatureTime }}
                </small>
                <hr class="d-none d-md-block" />
                <span class="col-6 d-none d-md-inline">
                  <div id="temperatureEauChartContainer">
                    <canvas id="temperatureEauChart"></canvas>
                  </div>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Section Météo -->
      <div class="container mt-5" v-if="openMeteoData">
        <h4 class="card-title titresections">Météo</h4>
        <div class="row align-items-center">
          <div class="col-lg-2 col-md-2 col-6">
            <div class="card text-center" style="opacity: 0.7">
              <div class="card-body">
                <small
                  class="card-title text-capitalize"
                  style="line-height: 0.1"
                >
                  {{ formatedDayDate(openMeteoData.daily.time[0]) }}<br />
                  {{ formatedDate(openMeteoData.daily.time[0]) }}
                </small>
                <p class="card-text display-6" style="line-height: 0.1">
                  <span style="display: block"
                    ><i
                      :class="
                        'wi ' + weatherIcon(openMeteoData.daily.weather_code[0])
                      "
                    ></i
                  ></span>
                  <span class="extrasmallfont"
                    >min {{ openMeteoData.daily.temperature_2m_min[0]
                    }}<i class="wi wi-celsius"></i><br />max
                    {{ openMeteoData.daily.temperature_2m_max[0]
                    }}<i class="wi wi-celsius"></i
                  ></span>
                </p>
              </div>
            </div>
          </div>
          <div class="col-lg-2 col-md-2 col-6">
            <div class="card text-center" style="opacity: 0.7">
              <div class="card-body">
                <small
                  class="card-title text-capitalize"
                  style="line-height: 0.1"
                >
                  {{ formatedDayDate(openMeteoData.daily.time[1]) }}<br />
                  {{ formatedDate(openMeteoData.daily.time[1]) }}
                </small>
                <p class="card-text display-6" style="line-height: 0.1">
                  <span style="display: block"
                    ><i
                      :class="
                        'wi ' + weatherIcon(openMeteoData.daily.weather_code[1])
                      "
                    ></i
                  ></span>
                  <span class="extrasmallfont"
                    >min {{ openMeteoData.daily.temperature_2m_min[1]
                    }}<i class="wi wi-celsius"></i><br />max
                    {{ openMeteoData.daily.temperature_2m_max[1]
                    }}<i class="wi wi-celsius"></i
                  ></span>
                </p>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-4 col-12">
            <div
              class="card text-center"
              style="border: 0.2em solid rgba(255, 255, 255, 0.8)"
            >
              <div class="card-body">
                <h4 class="text-capitalize">
                  {{ formatedDayDate(openMeteoData.current.time) }}
                </h4>
                <h6 class="card-title text-capitalize">
                  {{ formatedDate(openMeteoData.current.time) }}
                </h6>
                <div class="row">
                  <div class="col-lg-4 col-md-12 col-4">
                    <i class="display-6 wi wi-sunrise"></i><br />
                    {{ formatedHourDate(openMeteoData.daily.sunrise[2]) }}
                  </div>
                  <div class="col-lg-4 col-md-12 col-4">
                    <i
                      class="display-6"
                      :class="
                        'wi ' + weatherIcon(openMeteoData.current.weather_code)
                      "
                    ></i
                    ><br />
                    <small
                      >min {{ openMeteoData.daily.temperature_2m_min[3]
                      }}<i class="wi wi-celsius"></i><br />max
                      {{ openMeteoData.daily.temperature_2m_max[3]
                      }}<i class="wi wi-celsius"></i
                    ></small>
                  </div>
                  <div class="col-lg-4 col-md-12 col-4">
                    <i class="display-6 wi wi-sunset"></i><br />
                    {{ formatedHourDate(openMeteoData.daily.sunset[2]) }}
                  </div>
                </div>

                <div class="row">
                  <div class="col-6">
                    <i
                      class="display-6"
                      :class="
                        'wi wi-wind wi-from-' +
                        WindDirectionIcon(
                          openMeteoData.current.wind_direction_10m
                        )
                      "
                    ></i
                    ><br />
                    {{
                      getWindDirection(
                        openMeteoData.current.wind_direction_10m
                      )
                    }}<br />
                    {{ openMeteoData.current.wind_speed_10m }} km/h |
                    {{
                      Math.round(
                        (openMeteoData.current.wind_speed_10m / 1.852) * 10
                      ) / 10
                    }}
                    nd
                  </div>
                  <div class="col-6">
                    <i class="display-6 wi wi-umbrella"></i><br />
                    {{ openMeteoData.current.precipitation }} mm
                    <br />
                    {{ openMeteoData.current.relative_humidity_2m }} %
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-2 col-md-2 col-6">
            <div class="card text-center" style="opacity: 0.7">
              <div class="card-body">
                <small
                  class="card-title text-capitalize"
                  style="line-height: 0.1"
                >
                  {{ formatedDayDate(openMeteoData.daily.time[3]) }}<br />
                  {{ formatedDate(openMeteoData.daily.time[3]) }}
                </small>
                <p class="card-text display-6" style="line-height: 0.1">
                  <span style="display: block"
                    ><i
                      :class="
                        'wi ' + weatherIcon(openMeteoData.daily.weather_code[3])
                      "
                    ></i
                  ></span>
                  <span class="extrasmallfont"
                    >min {{ openMeteoData.daily.temperature_2m_min[3]
                    }}<i class="wi wi-celsius"></i><br />max
                    {{ openMeteoData.daily.temperature_2m_max[3]
                    }}<i class="wi wi-celsius"></i
                  ></span>
                </p>
              </div>
            </div>
          </div>
          <div class="col-lg-2 col-md-2 col-6">
            <div class="card text-center" style="opacity: 0.7">
              <div class="card-body">
                <small
                  class="card-title text-capitalize"
                  style="line-height: 0.1"
                >
                  {{ formatedDayDate(openMeteoData.daily.time[4]) }}<br />
                  {{ formatedDate(openMeteoData.daily.time[4]) }}
                </small>
                <p class="card-text display-6" style="line-height: 0.1">
                  <span style="display: block"
                    ><i
                      :class="
                        'wi ' + weatherIcon(openMeteoData.daily.weather_code[4])
                      "
                    ></i
                  ></span>
                  <span class="extrasmallfont"
                    >min {{ openMeteoData.daily.temperature_2m_min[4]
                    }}<i class="wi wi-celsius"></i><br />max
                    {{ openMeteoData.daily.temperature_2m_max[4]
                    }}<i class="wi wi-celsius"></i
                  ></span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Section Forecast -->
      <div class="container mt-5" v-if="openMeteoData">
        <h4 class="card-title titresections">Bulletin</h4>
        <div class="row">
          <div class="col-lg-12 col-md-12">
            <div class="card text-center">
              <div class="card-body">
                <div class="row">
                  <div class="col-3">
                    <img height="50em" src="@/assets/kite.svg" />
                    <span
                      class="material-symbols-outlined"
                      style="display: block; margin-top: 0.5em"
                      v-show="!openMeteoData.current.is_day"
                    >
                      dark_mode
                    </span>
                    <br />Kite
                    <span v-show="openMeteoData.current.is_day"
                      ><Rating v-model="kiteRate" readonly :cancel="false"
                    /></span>
                  </div>
                  <div class="col-3">
                    <img height="50em" src="@/assets/foil.svg" />
                    <span
                      class="material-symbols-outlined"
                      style="display: block; margin-top: 0.5em"
                      v-show="!openMeteoData.current.is_day"
                    >
                      dark_mode
                    </span>
                    <br />Wing
                    <span v-show="openMeteoData.current.is_day"
                      ><Rating v-model="wingRate" readonly :cancel="false"
                    /></span>
                  </div>
                  <div class="col-3">
                    <img height="50em" src="@/assets/wake.svg" />
                    <span
                      class="material-symbols-outlined"
                      style="display: block; margin-top: 0.5em"
                      v-show="!openMeteoData.current.is_day"
                    >
                      dark_mode
                    </span>
                    <br />Wake
                    <span v-show="openMeteoData.current.is_day"
                      ><Rating v-model="wakesurfRate" readonly :cancel="false"
                    /></span>
                  </div>
                  <div class="col-3">
                    <img height="50em" src="@/assets/swim.svg" />
                    <span
                      class="material-symbols-outlined"
                      style="display: block; margin-top: 0.5em"
                      v-show="!openMeteoData.current.is_day"
                    >
                      dark_mode
                    </span>
                    <br />Nage
                    <span v-show="openMeteoData.current.is_day"
                      ><Rating v-model="swimRate" readonly :cancel="false"
                    /></span>
                  </div>
                </div>
                <hr />
                <div class="row">
                  <div class="col-12">
                    <p
                      class="text-justify"
                      style="margin-bottom: 0; padding-bottom: 0"
                    >
                      En partenariat avec nos athlètes locaux nous vous offrons
                      un bulletin qui évalue le vent, sa vitesse, sa direction,
                      et d'autres facteurs météorologiques propres au lac
                      d'Annecy, afin de déterminer la qualité des conditions
                      pour la pratique de vos sports nautiques.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Section Webcam -->
      <div class="container mt-5">
        <h4 class="card-title titresections">Webcam</h4>
        <div class="row">
          <div class="col-lg-12 col-md-12">
            <div class="card text-center">
              <div class="card-body">
                <div class="row">
                  <div class="col-12">Webcam bientôt disponible</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="container mt-5">
        <div class="row">
          <div class="col-lg-12 col-md-12">
            <div class="card text-center">
              <div class="card-body">
                <h4 class="card-title">Soutenez votre Météo !</h4>
                <p class="card-text">
                  Aimez-vous notre service météo du Lac d'Annecy ? Vous pouvez
                  nous aider à le maintenir et l'améliorer en contribuant sur
                  Tipeee!
                </p>
                <a
                  href="https://fr.tipeee.com/meteolacannecy"
                  class="tipeee-project-cart-simple btn btn-primary"
                  data-orientation="line"
                  data-rewards="0"
                  >Soutenez avec Tipeee</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Footer avec mentions légales -->
      <footer class="mt-5 text-center text-white">
        <p>
          <a style="color: white" href="https://www.meteolacannecy.fr"
            >www.meteolacannecy.fr</a
          >
          | Météo Lac Annecy &copy; 2023. Tous droits réservés.<br />Développé
          par
          <a
            style="color: white"
            href="https://www.thecompagnie.eu"
            target="_blank"
            >TheCompagnie</a
          >
        </p>
      </footer>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { Chart } from "chart.js/auto";
import { format, startOfHour } from "date-fns";
import { fr } from "date-fns/locale";
import Rating from "primevue/rating";

export default {
  data() {
    return {
      feeds: [],
      lastTemperatureAir: null,
      lastTemperatureEau: null,
      lastTemperatureTime: null,
      meteoConceptApiKey: "", // Laissez cette propriété vide car nous utilisons une URL publique
      openMeteoData: null,
      showTemperatureAirChart: false,
      showTemperatureEauChart: false,
      externalTemperature: "",
    };
  },
  mounted() {
    this.fetchData();
    this.fetchopenMeteoData();
    this.fetchExternalContent();
    let script = document.createElement("script");
    script.async = true;
    script.src = "https://plugin.tipeee.com/widget.js";
    document.body.appendChild(script);
  },
  components: {
    Rating,
  },
  computed: {
    bodyClass() {
      return this.openMeteoData && this.openMeteoData.current.is_day
        ? "day-background"
        : "night-background";
    },
    wakesurfRate() {
      var rate = 3;
      if (this.openMeteoData.current.is_day) {
        if (this.openMeteoData.current.wind_speed_10m <= 5) {
          rate++;
        } else if (this.openMeteoData.current.wind_speed_10m >= 15) {
          rate--;
        } else if (this.openMeteoData.current.wind_speed_10m >= 20) {
          rate--;
        }
        if (this.openMeteoData.current.precipitation >= 0.5) {
          rate--;
        }
        if (this.lastTemperatureEau <= 10) {
          rate--;
        }
        if (this.lastTemperatureAir <= 15) {
          rate--;
        }
      } else {
        rate = 0;
      }
      return rate;
    },
    wingRate() {
      var rate = 1;
      if (this.openMeteoData.current.is_day) {
        if (this.openMeteoData.current.wind_speed_10m <= 15) {
          rate--;
        } else if (this.openMeteoData.current.wind_speed_10m <= 10) {
          rate--;
        } else if (this.openMeteoData.current.wind_speed_10m >= 55) {
          rate--;
        } else if (this.openMeteoData.current.wind_speed_10m >= 20) {
          rate++;
        }
        if (this.openMeteoData.current.precipitation >= 0.5) {
          rate--;
        }
        if (this.lastTemperatureEau <= 10) {
          rate--;
        }
        if (this.lastTemperatureAir <= 12) {
          rate--;
        }
      } else {
        rate = 0;
      }
      return rate;
    },
    kiteRate() {
      var rate = 1;
      if (this.openMeteoData.current.is_day) {
        if (this.openMeteoData.current.wind_speed_10m <= 20) {
          rate--;
        } else if (this.openMeteoData.current.wind_speed_10m <= 18) {
          rate--;
        } else if (this.openMeteoData.current.wind_speed_10m >= 23) {
          rate++;
        }
        if (this.openMeteoData.current.precipitation >= 0.5) {
          rate--;
        }
        if (this.lastTemperatureEau <= 10) {
          rate--;
        }
        if (this.lastTemperatureAir <= 12) {
          rate--;
        }
      } else {
        rate = 0;
      }
      return rate;
    },
    swimRate() {
      var rate = 3;
      if (this.openMeteoData.current.is_day) {
        if (this.openMeteoData.current.wind_speed_10m <= 5) {
          rate++;
        } else if (this.openMeteoData.current.wind_speed_10m >= 10) {
          rate--;
        }
        if (this.openMeteoData.current.precipitation >= 0.3) {
          rate--;
        }
        if (this.lastTemperatureEau >= 17) {
          rate++;
        } else if (this.lastTemperatureEau <= 15) {
          rate--;
        } else if (this.lastTemperatureEau <= 12) {
          rate--;
        }
        if (this.lastTemperatureAir <= 16) {
          rate--;
        }
      } else {
        rate = 0;
      }
      return rate;
    },
  },
  methods: {
    async fetchopenMeteoData() {
      try {
        const apiUrl =
          "https://api.open-meteo.com/v1/meteofrance?latitude=45.9088&longitude=6.1257&current=temperature_2m,relative_humidity_2m,is_day,precipitation,weather_code,wind_speed_10m,wind_direction_10m,wind_gusts_10m&hourly=precipitation,weather_code,wind_speed_10m,wind_direction_10m,wind_gusts_10m&daily=weather_code,temperature_2m_max,temperature_2m_min,sunrise,sunset,precipitation_sum,wind_speed_10m_max,wind_gusts_10m_max,wind_direction_10m_dominant&past_days=2&forecast_days=3";
        const response = await axios.get(apiUrl);
        this.openMeteoData = response.data;
      } catch (error) {
        console.error(
          "Erreur lors de la récupération des données open-meteo :",
          error
        );
      }
    },
    async fetchData() {
      try {
        const url =
          "https://api.thingspeak.com/channels/2377961/feeds.json?api_key=4MSBM14GH9UF0W9Y&results=8000";
        const response = await axios.get(url);
        this.feeds = response.data.feeds;

        const formattedFeeds = this.feeds.map((feed) => ({
          ...feed,
          created_at: format(new Date(feed.created_at), "yyyy-MM-dd HH:mm:ss"),
        }));

        const hourlyData = this.aggregateHourlyData(formattedFeeds);

        this.createTemperatureCharts(hourlyData);

        this.updateLastTemperatures();
      } catch (error) {
        console.error("Erreur lors de la récupération des données :", error);
      }
    },
    async fetchExternalContent() {
      const proxyUrl = "https://api.allorigins.win/get?url=";
      const targetUrl = encodeURIComponent(
        "https://annecy-meteo.com/temperature-du-lac.php"
      );

      try {
        const response = await fetch(proxyUrl + targetUrl);
        const data = await response.json();
        const parser = new DOMParser();
        const doc = parser.parseFromString(data.contents, "text/html");
        // Sélectionnez l'élément HTML contenant la température du lac
        const temperatureElement = doc.querySelector(
          "div.w3-display-middle h1.w3-jumbo.w3-animate-top"
        );
        if (temperatureElement) {
          const temperatureText = temperatureElement.innerText;
          const temperatureMatch = temperatureText.match(
            /Température du lac\s+([\d,]+)°C/
          );
          this.externalTemperature = temperatureMatch
            ? temperatureMatch[1]
            : "Non disponible";
        } else {
          this.externalTemperature = "Non disponible";
        }
      } catch (error) {
        console.error("Erreur:", error);
        this.externalTemperature = "Erreur lors de la récupération du contenu.";
      }
    },
    aggregateHourlyData(feeds) {
      const hourlyData = {};
      feeds.forEach((feed) => {
        const hourStart = startOfHour(new Date(feed.created_at));
        const hourKey = format(hourStart, "HH:mm");
        if (!hourlyData[hourKey]) {
          hourlyData[hourKey] = {
            temperatureAir: 0,
            temperatureEau: 0,
            count: 0,
          };
        }
        hourlyData[hourKey].temperatureAir += parseFloat(feed.field2);
        hourlyData[hourKey].temperatureEau += parseFloat(feed.field1);
        hourlyData[hourKey].count += 1;
      });

      Object.keys(hourlyData).forEach((hourKey) => {
        hourlyData[hourKey].temperatureAir /= hourlyData[hourKey].count;
        hourlyData[hourKey].temperatureEau /= hourlyData[hourKey].count;
      });

      return hourlyData;
    },
    createTemperatureCharts(feeds) {
      const feedsArray = Object.values(feeds);
      const feedsArrayKey = Object.keys(feeds);

      const temperatureAirData = feedsArray.map((feed) => feed.temperatureAir);
      const temperatureEauData = feedsArray.map((feed) => feed.temperatureEau);

      const labels = feedsArrayKey.map((feed) => feed);

      const ctxAir = document.getElementById("temperatureAirChart");
      if (ctxAir) {
        new Chart(ctxAir, {
          type: "bar",
          data: {
            labels: labels,
            datasets: [
              {
                label: "Température de l'Air depuis 24h (°C)",
                data: temperatureAirData,
                borderColor: "rgb(154, 235, 163)",
                backgroundColor: "rgb(154, 235, 163)",
                borderRadius: 4,
              },
            ],
          },
          options: {
            plugins: {
              legend: {
                labels: {
                  color: "#ffffff",
                },
              },
            },
            responsive: true,
            maintainAspectRatio: true,
            scales: {
              x: {
                ticks: {
                  color: "#ffffff",
                },
                title: {
                  color: "#ffffff",
                  display: true,
                  text: "Heure",
                },
              },
              y: {
                ticks: {
                  color: "#ffffff",
                },
                title: {
                  color: "#ffffff",
                  display: true,
                  text: "Température (°C)",
                },
              },
            },
          },
        });
      }

      const ctxEau = document.getElementById("temperatureEauChart");
      if (ctxEau) {
        new Chart(ctxEau, {
          type: "bar",
          data: {
            labels: labels,
            datasets: [
              {
                label: "Température de l'Eau depuis 24h (°C)",
                data: temperatureEauData,
                borderColor: "rgb(69, 196, 176)",
                backgroundColor: "rgb(69, 196, 176)",
                borderRadius: 4,
              },
            ],
          },
          options: {
            plugins: {
              legend: {
                labels: {
                  color: "#ffffff",
                },
              },
            },
            responsive: true,
            maintainAspectRatio: true,
            scales: {
              x: {
                ticks: {
                  color: "#ffffff",
                },
                title: {
                  color: "#ffffff",
                  display: true,
                  text: "Heure",
                },
              },
              y: {
                ticks: {
                  color: "#ffffff",
                },
                title: {
                  color: "#ffffff",
                  display: true,
                  text: "Température (°C)",
                },
              },
            },
          },
        });
      }
    },
    updateLastTemperatures() {
      // const lastEntry = this.feeds.slice(-1)[0];
      //this.lastTemperatureAir = lastEntry.field2;
      this.lastTemperatureAir = this.openMeteoData.current.temperature_2m;
      this.lastTemperatureEau = this.externalTemperature;
      this.lastTemperatureTime = format(Date.now(), "HH:mm");
    },
    getWindDirection(angle) {
      const directions = [
        "N",
        "NNE",
        "NE",
        "ENE",
        "E",
        "ESE",
        "SE",
        "SSE",
        "S",
        "SSO",
        "SO",
        "OSO",
        "O",
        "ONO",
        "NO",
        "NNO",
      ];
      const index = Math.round(angle / 22.5) % 16;
      return directions[index];
    },
    WindDirectionIcon(angle) {
      const directions = [
        "n",
        "nne",
        "ne",
        "ene",
        "e",
        "ese",
        "se",
        "sse",
        "s",
        "ssw",
        "sw",
        "wsw",
        "w",
        "wnw",
        "nw",
        "nnw",
      ];
      const index = Math.round(angle / 22.5) % 16;
      return directions[index];
    },
    weatherIcon(weatherCode) {
      if (weatherCode <= 9) {
        weatherCode = "0" + weatherCode;
      }
      weatherCode = "wi-wmo4680-" + weatherCode;
      return weatherCode; // Remplacez par le nom de la classe ou du chemin de l'icône correspondante
    },
    formatedDate(dateaformat) {
      return format(new Date(dateaformat), "dd/MM/yyyy", { locale: fr });
    },
    formatedDayDate(dateaformat) {
      return format(new Date(dateaformat), "EEEE", { locale: fr });
    },
    formatedHourDate(dateaformat) {
      return format(new Date(dateaformat), "HH:mm", { locale: fr });
    },
    classCouleurs(typeAnalize) {
      var classTemp = "normal";
      if (typeAnalize == "air") {
        if (this.lastTemperatureAir >= 25) {
          classTemp = "chaud";
        }
        if (this.lastTemperatureAir <= 12) {
          classTemp = "froid";
        }
      }
      if (typeAnalize == "eau") {
        if (this.lastTemperatureEau >= 23) {
          classTemp = "chaud";
        }
        if (this.lastTemperatureEau >= 15) {
          classTemp = "froid";
        }
      }
      return classTemp;
    },
  },
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");
@import url("https://fonts.googleapis.com/icon?family=Material+Symbols+Outlined");
/* Importer la police Montserrat */
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("./assets/weather-icons.min.css");
@import url("./assets/weather-icons-wind.min.css");

/* Appliquer la police Montserrat avec différentes tailles et poids */
body {
  font-family: "Montserrat", sans-serif;
  font-weight: 400 !important; /* Poids normal */
  font-size: 16px; /* Taille de police normale */
  background-image: url("./assets/fond.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;
  padding-bottom: 0 !important;
  margin-bottom: 0 !important;
  color: white !important;
}

h1 {
  font-family: "Montserrat", sans-serif;
  font-weight: 800 !important; /* Gras */
  font-size: 24px; /* Taille de police plus grande */
}

p {
  font-family: "Montserrat", sans-serif;
  font-weight: 300 !important; /* Léger */
  font-size: 14px; /* Taille de police plus petite */
}

b {
  font-weight: 600 !important; /* Léger */
}

.container.mt-5 {
  margin-top: 1.5em !important;
}

canvas {
  min-height: 18vh;
}

.header {
  font-family: "Montserrat", sans-serif;
  font-weight: 200 !important; /* Gras */
  font-size: 5em; /* Taille de police plus grande */
  line-height: 1em;
  padding-top: 1em;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0), rgba(30, 50, 49, 0));
  color: rgba(255, 255, 255, 1) !important;
  b {
    font-weight: 800 !important; /* Gras */
  }
}
.header-small {
  font-family: "Montserrat", sans-serif;
  font-weight: 100 !important; /* Gras */
  font-size: 2em; /* Taille de police plus grande */
}

.logolac {
  opacity: 1;
  width: 50vw;
  display: inline-block;
  margin-left: 0.5em;
}

.text-white {
  color: white;
}

.globalFond {
  background-color: rgba(30, 50, 49, 0); /* Couleur de fond */
  padding-top: 2em;
  padding-bottom: 1em;
  margin-bottom: 1em;
  margin-top: 1em;
  border-radius: 1em;
  .card {
    font-family: "Montserrat", sans-serif;
    margin-bottom: 0.5em;
    padding: 0.5em;
    background: linear-gradient(
      180deg,
      rgba(30, 50, 49, 0.4),
      rgba(30, 50, 49, 0.2)
    ); /* Couleur de fond */
    border: none;
    border-radius: 0.3em;
    backdrop-filter: blur(20px);
    color: white !important;
  }
}
.p-rating {
  display: block !important;
}
/* Styles pour la condition "froid" */
.froid {
  color: #28afb0; /* Couleur du texte */
}

/* Styles pour la condition "chaud" */
.chaud {
  color: #ee964b; /* Couleur du texte */
}

/* Styles pour la condition "nuit" */
.normal {
  color: #ffffff; /* Couleur du texte */
}
.day-background {
  background-image: url("./assets/fond.png"); /* Image de fond pour le jour */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;
}

/* Styles de transition */
.slide-enter-active,
.slide-leave-active,
.card-body {
  transition: all 0.5s ease;
}
.container {
  transition-duration: 1s;
}
.slide-enter,
.slide-leave-to {
  opacity: 0;
  transform: translateY(10px);
}

.night-background {
  background-image: url("./assets/fondnuit.png"); /* Image de fond pour la nuit */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;
}

.titresections {
  display: none;
  text-align: center;
  margin-top: 1em;
  padding-top: 0em;
  text-shadow: 1px 1px 10px rgba(0, 0, 0, 0.6);
  font-size: 2em;
  font-weight: bold;
  text-transform: uppercase;
}

.extrasmallfont {
  font-size: 0.3em;
}

.btn-primary {
  margin-top: 0.2em !important;
  background-color: #28afb0 !important; /* Couleur de fond turquoise */
  border-color: #28afb0 !important; /* Bordure de même couleur turquoise */
  color: white !important; /* Texte en blanc */
  font-weight: 500 !important; /* Texte en gras */
  padding: 10px 20px !important; /* Espacement intérieur pour agrandir le bouton */
  border-radius: 5px !important; /* Bords arrondis */
  transition: background-color 0.3s, border-color 0.3s, color 0.3s !important; /* Transition douce lors du survol */

  &:hover,
  &:focus {
    background-color: #206b6d !important; /* Couleur de fond plus foncée au survol */
    border-color: #206b6d !important; /* Bordure plus foncée au survol */
    color: #ffffff !important; /* Texte en blanc */
    text-decoration: none !important; /* Pas de soulignement */
  }
}

@media (max-width: 768px) {
  body {
    font-size: 0.7em !important; /* Taille de police réduite pour les titres h1 sur les écrans mobiles */
  }
  h1 {
    font-size: 2.5em !important; /* Taille de police réduite pour les titres h1 sur les écrans mobiles */
  }

  p {
    font-size: 1em !important; /* Taille de police réduite pour les titres h1 sur les écrans mobiles */
    margin-bottom: 0 !important;
  }

  h4 {
    font-size: 1.2em !important; /* Taille de police réduite pour les titres h1 sur les écrans mobiles */
  }

  h6 {
    font-size: 0.9em !important; /* Taille de police réduite pour les titres h1 sur les écrans mobiles */
  }
  .card-body {
    padding: 0.9em !important;
  }
  small {
    font-size: 1em !important; /* Taille de police réduite pour les titres h1 sur les écrans mobiles */
  }
  i {
    font-size: 1.5em;
  }
  .extrasmallfont {
    font-size: 1em;
  }
  .row {
    --bs-gutter-x: 0.4rem !important;
    --bs-gutter-y: 0.4rem !important;
  }
  .container.mt-5 {
    margin-top: 0.4em !important;
  }
  .p-icon {
    height: 1em;
    margin: 0;
  }
}
</style>
